@font-face {
  font-family: "Courier New Shipped";
  src: url("./CourierNew.woff");
}

.App {
  font-family: "Courier New Shipped";
  text-align: center;
  background-color: var(--main-bg-colour);
  color: var(--text-colour);
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.app-content {
  flex-shrink: 0;
  flex-basis: 900px;
  padding: 10px 10px 100px 10px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  text-transform: uppercase;

  font-weight: bolder;
  text-align: left;
  transform: skew(-15deg);
}

.header .row {
  display: flex;
  flex-direction: row;
}

.header .filler {
  color: var(--border-colour);
}

.header .error {
  color: var(--highlight-colour);
}

.header .highlight {
  color: var(--highlight-colour);
}

.header .highlight2 {
  color: #9C6250;
}

.header .highlight3 {
  color: #CAAB9A;
}

.header .border.reset {
  color: var(--text-colour);
}

.main-body {
  margin-top: 40px;
}

.section.experience {
  padding: 10px;
}

.updating-text {
  margin-top: 20px;
}

.updating-text .line > * {
  padding-top: 2px;
}

@keyframes flashing {
  0%, 75%, 100% { background-color: var(--link-colour); }
  80%, 99% { background-color: transparent; }
}

.updating-text .line .cursor {
  animation: flashing 1.2s infinite;
}

.canvas-container {
  flex-shrink: 1;
  flex-grow: 1;
  height: 100vh;
  position: fixed;
  top: 0;
}

.left .canvas-container {
  left: 0;
}

.right .canvas-container {
  right: 0;
}

.demo-canvas {
  width: 100%;
}

.section {
  text-align: left;
}

.experience {
  margin-top: 50px;
}

.job {
  margin-top: 50px;
  display: flex;
}

.job .title {
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (max-width: 900px) {
  .app-content {
    flex-shrink: 0;
    flex-basis: unset;
    width: 100vw;
  }
  .mobile-body {
    padding: 0 10px;
  }
}
