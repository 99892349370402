.logo {
    font-size: 6px;
    padding: 10px;
}

.logo a {
    text-decoration: none;
}

@keyframes brightenloop {
    0%, 50%, 100% {filter: brightness(100%) }
    25% { filter: brightness(150%) }
}

.netcraft .color-p {
    color: #76539C;
}
.netcraft .color-r {
    color: #D7496A;
}
.netcraft .color-o {
    color: #EA6F3A;
}
.netcraft .color-y {
    color: #F0B627;
}
.netcraft .color-g {
    color: #8AC640;
}
.netcraft .color-a {
    color: #5EBFB2;
}
.netcraft .color-b {
    color: #000000;
}

.netcraft:hover .color-p {
    animation: brightenloop linear infinite 1.2s 0s;
}

.netcraft:hover .color-r {
    animation: brightenloop linear infinite 1.2s 0.2s;
}

.netcraft:hover .color-o {
    animation: brightenloop linear infinite 1.2s 0.4s;
}

.netcraft:hover .color-y {
    animation: brightenloop linear infinite 1.2s 0.6s;
}

.netcraft:hover .color-g {
    animation: brightenloop linear infinite 1.2s 0.8s;
}

.netcraft:hover .color-a {
    animation: brightenloop linear infinite 1.2s 1.0s;
}

.palantir .color-b {
    color: #0F0F0F;
}

.palantir .color-w {
    color: #F7F3E3;
}

.optimal:hover {
    filter: brightness(150%);
}

.logo.palantir:hover .color-w {
    color: #0F0F0F;
    transition: color linear 0.2s 0.3s;
}

.logo.palantir:hover .color-b {
    color: #F7F3E3;
    transition: color linear 0.5s;
}

.optimal .color-w {
    color: #FFFFFF;
}

.optimal .color-b {
    color: #1C496C;
}

.projects .color-g {
    color: #8AA38B;
}

.projects .color-b {
    color: #4b2414;
}

.projects .color-w {
    color: #FFFFFF;
}

.projects .color-s {
    color: #87CEEB;
}

.projects .color-h {
    color: #7CFC00;
}

.projects .color-k {
    color: #000000;
}

.projects .color-p {
    color: #77216f;
}

.projects .color-n {
    color: #deccba;
}

.projects .color-r {
    color: #9d0b0b;
}

.projects .color-y {
    color: #6e6e6e;
}